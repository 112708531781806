<div id="logConsole" class="consoleMaxSize">
    <div class="ui segment innereventlog triggerbar" style="cursor: pointer; margin: 0" (click)="toggle($event)">
        
        <i class="chevron circle right icon" style="margin-left: 5px"></i>
        <p style="display: inline">Event Logs</p>
        
        <button style="visibility: hidden; display: inline" class="ui primary button" >
            Just giving height to the element.
        </button>
        <sui-tabset [style.display]="this.state.consoleOpen ? 'unset' : 'none'" (click)="stopClickPropagation($event)">
            <div class="ui pointing secondary menu">
                <!-- note: programatically add "active" class to activate tabs -->
                <!-- BS tab library doesn't hide contents for whatever reason, need to show/hide manually with (click) handler -->
                <a class="consoleTab item" suiTabHeader="1" (click)="showTabContent('logs')">Console</a>
                <a class="consoleTab item" suiTabHeader="2" (click)="showTabContent('repl')">REPL</a>
            </div>
            
            <div class="ui inverted segment content scrollbar scrollbar-dark stylefix" suiTabContent="1" [style.display]="this.state.consoleOpen && this.activeTab == 'logs' ? 'block' : 'none'">
                <div class="scrollbar scrollbar-dark" [style.display]="this.state.consoleOpen ? 'block' : 'none'" >
                    <sui-accordion [transition]="'fade'" [transitionDuration]="300" [closeOthers]="false">
                        <app-one-log *ngFor="let oneLog of logs; let i = index" class="oneLog" [log]="oneLog" [open]="true" ></app-one-log>
                    </sui-accordion>
                   
                </div>
            </div>

            <div class="ui segment" suiTabContent="2" (click)="setIsReplFocused(true)" (clickOutside)="this.setIsReplFocused(false)"  style="padding: 0 !important" [style.display]="this.state.consoleOpen && this.activeTab == 'repl' ? 'block' : 'none'">
                <div class="box">
                    <div class="box-container">
                      <div class="row content">
                        <ngx-terminal #terminal [login]="login" [server]="server" (command)="onCommand($event)"></ngx-terminal>
                      </div>
                    </div>
                  </div>
            </div>

        </sui-tabset>
    </div>

<!--         <div class="content scrollbar scrollbar--dark" [style.display]="this.state.consoleOpen ? 'block' : 'none'" >
            <sui-accordion [transition]="'fade'" [transitionDuration]="300" [closeOthers]="false">
                <app-one-log *ngFor="let oneLog of logs; let i = index" class="oneLog" [log]="oneLog" [open]="true" ></app-one-log>
            </sui-accordion>
           
        </div> -->
</div>