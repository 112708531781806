import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mainloader',
  templateUrl: './mainloader.component.html',
  styleUrls: ['./mainloader.component.css']
})
export class MainloaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
