<sui-tabset *ngIf="compiler.Chain">
    <div class="ui pointing secondary menu">
        <a class="item" suiTabHeader="1">Value</a>
        <a class="item" suiTabHeader="2">Gas Amount</a>
        <a class="item" suiTabHeader="3">Gas price</a>
    </div>

    

    <div  class="ui" suiTabContent="1">
        <div class="ui right labeled input"
        [class.ui]="true"
        [class.right]="true"
        [class.labeled]="true"
        [class.input]="true" 
        [class.disabled]="(auth.user$ | async) ? false : compiler.Chain.currentWalletProvider == 'extension' ? false : true" 
        suiPopup popupHeader="Please Note:" popupText="Please use the wallet extension or login/register for free to use this feature! :)" popupPlacement="top center" popupDelay="600" popupTrigger="manual" #popup="suiPopup"
        (mouseover)="openPopup(popup)" (mouseout)="closePopup(popup)"
        >
            <input type="text" placeholder="0" inputmode="numeric" pattern="[0-9]*" digitOnly [(ngModel)]="currentInput" (keyup)="valueChange($event)" (change)="valueChange($event)">
    
                <div id="txvalueButton" class="ui primary dropdown button" suiDropdown [(isOpen)]="beOpen" [isDisabled]="(auth.user$ | async) ? false : compiler.Chain.currentWalletProvider == 'extension' ? false : true">
                <div class="text">{{currentUnit}}</div>
                <i class="dropdown icon"></i>
                <!-- <span class="description"> 10<sup>{{units[key]}}</sup> </span> -->
                <div class="menu"  suiDropdownMenu>
                    

                    <div class="item" *ngFor="let key of objectKeys(units)" (click)="setUnit(key)"> {{key}} <span class="description"> 10<sup>{{units[key]}}</sup> </span> </div>
                   
                </div>
            </div>
          </div>
        </div>
    <div class="ui" suiTabContent="2">
        <sui-checkbox class="slider"  [(ngModel)]="gasRadioButton" (click)="toggleGasRadioButton()">  </sui-checkbox>
        <div [class.ui]="true" [class.input]="true" [class.disabled]="!gasRadioButton">
            
            <!--     <label>Checkbox Style Examples</label> -->
                <div class="field">
                    
                    <!-- <input type="text" placeholder={{gasRadioButton ? "0" : "Auto (recommended)"}} inputmode="numeric" pattern="[0-9]*" digitOnly [(ngModel)]="manualGasValue" (keyup)="manualGasChange($event)" (change)="manualGasChange($event)"> -->
                    <input type="text" placeholder="{{ (gasRadioButton ? manualGasValue : 'Auto (recommended)' )}}" inputmode="numeric" pattern="[0-9]*" digitOnly [(ngModel)]="manualGasValue" (keyup)="manualGasChange($event)" (change)="manualGasChange($event)">
                </div>
        </div>
    </div>
    <div [class.ui]="true" [class.input]="true" [class.disabled]="!gasPriceRadioButton"suiTabContent="3">

        <sui-checkbox class="slider"  [(ngModel)]="gasPriceRadioButton" (click)="toggleGasPriceRadioButton()">  </sui-checkbox>
        <div class="ui input">
            
            <!--     <label>Checkbox Style Examples</label> -->
                <div class="field">
                    
                    <!-- <input type="text" placeholder={{gasRadioButton ? "0" : "Auto (recommended)"}} inputmode="numeric" pattern="[0-9]*" digitOnly [(ngModel)]="manualGasValue" (keyup)="manualGasChange($event)" (change)="manualGasChange($event)"> -->
                    <input type="text" placeholder="{{ (gasPriceRadioButton ? manualGasPriceValue : 'Auto (recommended)' )}}" inputmode="numeric" pattern="[0-9]*" digitOnly [(ngModel)]="manualGasPriceValue" (keyup)="manualGasPriceChange($event)" (change)="manualGasPriceChange($event)">
                </div>
            
        </div>

    </div>




</sui-tabset>
