<!-- <meta charset="utf-8">
<meta property="og:title" content="AE Studio">
<meta property="og:site_name" content="AE Studio">
<meta property="og:url" content="https://studio.aepps.com">
<meta property="og:description" content="The Contract editor that does the work for you, not the other way round.">
<meta property="og:type" content="product">
<meta property="og:image" content="https://studio.aepps.com/assets/preview.png"> -->
<!--The content below is only a placeholder and can be replaced.-->

 <div class="noMarginNoPadding ui two column stackable grid container">
  <div class="noMarginNoPadding ten wide column resizable-horizontally" style="padding-bottom: 0px; resize: horizontal;">
    <app-editor></app-editor>
  </div>
  <div style="height: 100vh; overflow-y: scroll; overflow-x: hidden" class="noMarginButPadding ui segment six wide column">
    <contract-menu-sidebar> </contract-menu-sidebar>
  </div>
</div>

<router-outlet></router-outlet>

